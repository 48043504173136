body {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Fix for alignment of COREUI's filter and Datagrid components. This css code is copied from https://git.corp.tanium.com/tanium/coreui/blob/c7b92cc8ca048fa652a5c35a4a338b5d13956a01/.storybook/styles/bootstrap.css.
* COREUI uses this css in their storybook to mimic what the bootstrap loaded by the console does. Since TaaS is out of Tanium console context, we has to add below code.
*/
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}
